
.container{
    height: 100vh;
    display: flex;

    @media (max-width: 420px) {
        flex-direction: column;
        height: auto;
    }
}


.header{
   width: 50%;
   position: fixed;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   height: 100vh;
   
   @media (max-width: 420px) {
      position: unset;
      width: 100%; 
      height: auto;
    }
   
    
   .header-content{
       max-width: 80%;
       margin: 5%;

       @media (max-width: 420px) {
        max-width: 100%; 
      }
       
       h1{
        font-size: 1.6rem;
        font-weight: 700;
        padding: 2%;
       }

       h2{
        font-size: 1.6rem;
        font-weight: 200;
        padding: 2%;
        line-height: 2;
        color: $secondaryFont;
       }

       h3{
        font-size: 1.6rem;
        font-weight: 200;
           padding: 2%;
           line-height: 2;
           color: $secondaryFont;
           
           .twitter-link{
               font-size: inherit;
               color: inherit;
               text-decoration: underline;

               &:hover{
                color: #2ea1f2;
               }
           }

       }

       form{
           padding-left: 2%;
           margin: 2% 0;
           position: relative;
           display: flex;
        
           @media (max-width: 800px) {
            justify-content: start;
        }
           

           input{
               background-color:#F8F9FA;
               border: none;
               font-size: 1.6rem;
               padding: 8px;
               outline: none;

               &:nth-of-type(1){
                   margin-right: 3px;
                   @media (max-width: 1024px) {
                    width:30%;
                   }
                   
               }

               @media (max-width: 800px) {
                width:50%;
               }
           }

           button{
               background-color: $mainColor;
               color: white;
               border: none;
               outline: none; 

               &:hover{
                background-color: white;
                color: $mainFont;
                border: 1px solid $mainColor
               }

               &:disabled{
                   opacity: .2;
               }
           }


       }

       .error{
           @extend h2;
           font-size:1.3rem;

           .twitter-link-err{
            font-size: inherit;
            color: inherit;
            text-decoration: underline;

            &:hover{
                color: #2ea1f2;
            }
        }
       }

       
   }
   
}

.content{
    width:50%;
    position: absolute;
    right: 0;

    @media (max-width: 420px) {
       width: 90%;
       position: unset;
    }
   
}

