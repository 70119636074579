
.content{
    margin: 3%;
    
    
    .content-post{
        margin-bottom: 30vh;
        li{
            font-size: 2rem;
            border-top: .2px solid $secondaryFont;
            padding: 16px 0;
            color: $mainFont
        }
        a{
            font-size: 1.6rem;
            color: $secondaryFont;
            font-weight: 100;

            &:hover{
                font-weight: 400;
                color: $mainColor;
            }
        }
    }
}   